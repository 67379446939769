// -----------------------------------------------------------------------------------------------------
// @ Theming
// -----------------------------------------------------------------------------------------------------
@use "@angular/material" as mat;
@import "styles/common";

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------
// Angular Material typography
// Reference: https://material.angular.io/guide/typography#typography-levels

$typography: mat.define-typography-config(
  $font-family: "Lato, Helvetica Neue, Arial, sans-serif",
  /**
  * headline-5: native element h1
  */ $headline-5: mat.define-typography-level(24px, 32px, 700),
  /**
   * Headline-6: native element h2, card title, dialog title
   */ $headline-6:
    mat.define-typography-level(20px, 28px, 600),
  /**
  * subtitle-1: native element h3
  */ $subtitle-1: mat.define-typography-level(18px, 28px),
  /**
  * body-1: root body font, native element h4
  */ $body-1: mat.define-typography-level(16px, 24px, 400, null),
  /**
  * body-2: main body text
  */ $body-2: mat.define-typography-level(14px, 20px, 400, null),
  /**
  * subtitle-2: used in expansion title. Should try to match headline-6
  */ $subtitle-2:
    mat.define-typography-level(14px, 18px, 600),
  $button: mat.define-typography-level(14px, 14px, 600),
);

@include mat.all-component-typographies($typography);

.mat-typography.mat-typography {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

// Abbr
abbr {
  cursor: help;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.54);
}

// Blockquote
blockquote {
  border-left: 3px solid rgba(0, 0, 0, 0.12);
  font-style: italic;
  margin: 1em 0;
  padding-left: 16px;

  footer {
    font-style: normal;

    &:before {
      content: "\2014 \00A0";
    }
  }

  &.reverse {
    border-left: none;
    border-right: 3px solid rgba(0, 0, 0, 0.12);
    text-align: right;
    padding-left: 0;
    padding-right: 16px;

    footer {
      &:before {
        content: "";
      }

      &:after {
        content: "\2014 \00A0";
      }
    }
  }
}

// Pre
pre {
  line-height: 1.6;
  margin: 8px 16px;
  white-space: pre-wrap;
}

// Table
table {
  thead {
    tr {
      th {
        text-align: left;
      }
    }
  }
}

// Truncate
.text-truncate {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
